import React from "react";

export default function BundleCard({ data, index }) {
  const { bundleType, bundleName, image, price, link, benefits, inStock } =
    data[0];

  return (
    <div
      className="group relative bg-[#fff] rounded flex flex-col w-full p-6 hover:scale-105 duration-300 ease-in-out"
      style={{
        boxShadow: "0px 31.0344px 39.9014px rgba(224, 224, 224, 0.25)",
      }}>
      {!inStock && (
        <div className="absolute top-1 right-1 w-12 text-[10px] h-12 bg-cstm-green text-white flex items-center justify-center text-center rounded-full">
          <span className="font-medium">Out of Stock</span>
        </div>
      )}

      <div className="flex flex-col justify-between h-full px-8 pt-8 pb-12 border border-[#9CE582] rounded">
        <div className="flex flex-col items-center h-full">
          <div className="mb-8">
            <img
              src={image}
              alt={bundleName}
              className={`
            ${index === 0 ? "px-6" : ""}
            h-auto lg:h-80 object-contain`}
            />
          </div>
          <h4 className="mt-6 text-lg md:text-xl lg:text-2xl font-bold capitalize text-cstm-green text-center bundle-name">
            {bundleType}
          </h4>
          <p className="mt-8 font-semibold mb-6 lg:mb-8 text-2xl lg:text-3xl text-cstm-green">
            <span>&#x20A6;</span> {price.toLocaleString()}
          </p>

          <div className="flex flex-col mb-8 lg:mb-12">
            {benefits.map((benefit, index) => {
              const { icon, text, isAvailable } = benefit;

              return (
                <div className="flex items-center gap-x-4 mb-4" key={index}>
                  <div children="ml-4">
                    <img src={icon} alt="icon" />
                  </div>
                  {isAvailable ? (
                    <p className="text-cstm-green font-bold capitalize">
                      {text}
                    </p>
                  ) : (
                    <p className="text-[#A9A9A9] capitalize">{text}</p>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {inStock && (
          <a
            href={link}
            target={"_blank"}
            rel="noopener noreferrer"
            className="py-4 px-8 block w-full text-center text-[#fff] font-medium bg-cstm-green rounded text-sm lg:text-sm-15 xl:text-base"
            to="./">
            Buy Now
          </a>
        )}
      </div>
    </div>
  );
}
