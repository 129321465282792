import React from "react";
import * as Animate from "react-reveal";

import CapsuleFeatureImage from "../../../src/assets/images/energybox/reeddi-energybox-showcase.webp";

export default function Showcase() {
  return (
    <section className="">
      <div className="w-11/12 m-auto pt-12 md:pt-16 lg:pt-20 pb-10 lg:pb-24 max-w-7xl">
        <Animate.Fade bottom>
          <div className="flex-col gap-y-4">
            <h3 className="text-3xl font-semibold md:text-3xl lg:text-4xl xl:text-5xl text-center">
              The Reeddi EnergyBox<span className="text-cstm-green">.</span>
            </h3>
          </div>
          <div className="mt-10 md:mt-14 lg:mt-16 w-full relative">
            <div
              className="w-full h-[118px] lg:h-[236px] absolute right-0 left-0 bottom-0"
              style={{
                backgroundImage:
                  "linear-gradient(180deg, rgba(25, 38, 53, 0) 0%, #06101D 81.15%)",
              }}
            ></div>
            <p className="absolute bottom-5 right-5 lg:bottom-8 lg:right-8 text-3xl text-white sm:text-4xl md:text-5xl lg:text-6xl xl:text-[64px] font-bold">
              Endless Power<span className="text-cstm-green">.</span>
            </p>
            <img src={CapsuleFeatureImage} alt="capsule-features" />
          </div>
        </Animate.Fade>
      </div>
    </section>
  );
}
