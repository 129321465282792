import React from "react";
import useSticky from "../../hooks/useSticky";
import BuyNow from "./Buynow";
import Rent from "./Rent";
import CapsuleIcon from "../../../src/assets/images/energybox/reeddi-energybox.png";

export default function Stickybar({ handleShowModal }) {
  const { element } = useSticky();

  return (
    <section
      ref={element}
      className="bg-[#fff] flex items-center fixed z-10 w-full top-20 transition all duration-300 ease-in-out scroll-hide"
    >
      <div className="w-11/12 m-auto my-3 max-w-7xl">
        <div className="flex justify-between items-center max-w-[680px]">
          <div className="hidden md:flex flex-row justify-between items-center space-x-4">
            <div className="flex items-center justify-center w-16 py-2.5 bg-[#F5FFFB]">
              <img src={CapsuleIcon} className="w-10 rounded-md" alt="icon" />
            </div>
            <p className="flex text-sm lg:text-sm-15">
              Get the Reeddi EnergyBox now
            </p>
          </div>

          <div className="flex space-x-5 w-full sm:space-x-7 md:w-[355px] lg:space-x-8">
            <BuyNow textSize="xs" handleShowModal={handleShowModal} />
            <Rent textSize="xs" />
          </div>
        </div>
      </div>
    </section>
  );
}
