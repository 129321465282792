import React from "react";
import * as Animate from "react-reveal";
import EasyToUse from "../../assets/images/energybox/reeddi-energy-box-easy-to-use.webp";
import HighlyDurable from "../../assets/images/energybox/reeddi-energy-highly-durable.webp";
import Portability from "../../assets/images/energybox/reeddi-energy-portability.webp";

export default function Diff() {
  return (
    <section className="">
      <div className="w-11/12 m-auto pt-14 pb-14 lg:pt-20 lg:pb-14 max-w-7xl">
        <div className="flex flex-col justify-center items-center">
          <Animate.Fade bottom>
            <div className=" flex flex-col mb-12 md:mb-16 lg:mb-24 w-full lg:w-10/12">
              <h3 className="capitalize w-10/12 md:w-full lg:w-8/12 lg:leading-10 mx-auto text-3xl mb-4 lg:mb-6 text-center font-semibold md:text-3xl lg:text-4xl xl:text-5xl">
                Experience the power of clean and reliable energy!
              </h3>
              <p className="text-cstm-grey text-base md:text-lg md:leading-8 text-center w-full md:w-10/12 lg:w-9/12 mx-auto lg:px-3">
                Whether you’re off the grid or experiencing power outages, the
                EnergyBox ensures that you’re always connected and never in the
                dark.
              </p>
            </div>
          </Animate.Fade>
        </div>
      </div>
      <div className="w-11/12 m-auto max-w-7xl">
        <Animate.Fade bottom>
          <div className="flex flex-col space-y-5 md:flex-row md:space-y-0 md:space-x-5 lg:space-x-8">
            <div className="flex flex-col space-y-5 justify-between lg:space-y-8">
              <img className="w-fit" src={Portability} alt="" />
              <img className="w-fit" src={HighlyDurable} alt="" />
            </div>
            <div>
              <img className="w-fit" src={EasyToUse} alt="" />
            </div>
          </div>
        </Animate.Fade>
      </div>
    </section>
  );
}
