import React from "react";
import { BundleData } from "./BundleData";
import BundleCard from "./BundleCard";
import * as Animate from "react-reveal";

export default function Bundles() {
  return (
    <section className="">
      <div className="w-11/12 m-auto py-16 lg:py-24 max-w-7xl">
        <div className="flex flex-col md:gap-y-16">
          <Animate.Fade bottom>
            <h2 className="w-11/12 md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto text-center mb-8 md:mb-0  font-semibold text-3xl md:text-4xl lg:text-4xl xl:text-5xl capitalize">
              The best, simple, transparent pricing that works for you
            </h2>
            <div className="w-full max-w-4xl mx-auto grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-8 my-4">
              {BundleData.map((data, index) => {
                return <BundleCard key={index} data={data} index={index} />;
              })}
            </div>
          </Animate.Fade>
        </div>
      </div>
    </section>
  );
}
