import React from 'react'

export default function Card({ data }) {
    const { image, title, text, comparison, barchart } = data
    return (
        <div className='pb-16 sm:pb-0 flex flex-col md:flex-row justify-between items-center cursor-grab'>
            <div className='w-full md:w-[43%] overflow-hidden rounded mb-4'>
                <img className='w-fit' src={image} alt='testimonialimage' />
            </div>

            <div className='w-full flex flex-col md:max-w-[50%] pt-8 lg:pt-4'>
                <div className='w-full  flex flex-col mb-8'>
                    <h4 className='w-full font-semibold  mb-4 text-xl md:text-2xl lg:text-3xl xl:text-4xl md:max-w-[350px] lg:max-w-[500px] capitalize'>{title}</h4>
                    <p className='mt-3 w-full text-cstm-grey text-sm leading-6 md:text-base md:w-11/12 capitalize'>{text}</p>
                </div>

                <div className='mb-8'>
                    <img src={comparison} alt='comparison' />
                </div>

                <div className="-ml-4 lg:-ml-6">
                    <img src={barchart} alt="barchart" />
                </div>
            </div>
        </div>
    );
}



