import React, { useRef, useContext } from "react";
import Card from "./Card";
import { App } from "../../../context/appContext";
import { dataMap } from "./data";
import { Navigation, Parallax, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import * as Animate from "react-reveal";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/parallax";
import "../../BigEnergyComponents/pagination.css";

const PrevBtn = React.forwardRef((props, ref) => {
  return (
    <span ref={ref} className="group rounded-full cursor-pointer">
      {props.children}
    </span>
  );
});

const NextBtn = React.forwardRef((props, ref) => {
  return (
    <span ref={ref} className="group rounded-full cursor-pointer">
      {props.children}
    </span>
  );
});

function DesktopCarousel() {
  const nextRef = useRef(null);
  const prevRef = useRef(null);
  const SwiperRef = useRef(null);

  const { currentSlide, setCurrentSlide } = useContext(App);

  return (
    <>
      <Swiper
        spaceBetween={30}
        modules={[Parallax, Navigation, Pagination, Autoplay]}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        className="mt-8 lg:mt-16  w-full"
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        speed={600}
        parallax={true}
        onSlideChange={(swiper) => {
          setCurrentSlide(swiper.activeIndex);
        }}
        autoplay={true}
        autoPlay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        ref={SwiperRef}
        loop={true}
      >
        {dataMap.map((data, index) => {
          return (
            <SwiperSlide key={index}>
              <Card key={data.id} data={data} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

function MobileCarousel() {
  return (
    <Swiper
      spaceBetween={30}
      modules={[Parallax, Pagination, Autoplay]}
      className="w-full"
      speed={600}
      parallax={true}
      pagination={true}
      autoplay={true}
      autoPlay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      loop={true}
    >
      {dataMap.map((data, index) => {
        return (
          <SwiperSlide key={index}>
            <Card key={data.id} data={data} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default function Measures() {
  const breakPoint = useBreakpoint();

  return (
    <section
      className="bg-[#ffffff]"
      style={{ boxShadow: "0px 31.0344px 39.9014px rgba(224, 224, 224, 0.25)" }}
    >
      <div className="w-11/12 m-auto pt-16 lg:pt-24 pb-10 lg:pb-14 md:pb-4 max-w-7xl">
        <div className="flex flex-col justify-center items-center">
          <div className=" flex flex-col items-center max-w-[600px] lg:max-w-[1000px]">
            <Animate.Fade bottom>
              <h3 className="text-center mb-6 lg:mb-6 font-semibold text-3xl md:text-3xl lg:text-4xl xl:text-5xl">
                How Reeddi EnergyBox Makes The Future
                <span className="text-cstm-green">.</span>
              </h3>
              <p className=" mb-12 lg:mb-12 selection: text-cstm-grey text-base lg:text-lg lg:leading-9 text-center max-w-[700px] capitalize">
                With a data-driven and locally focused approach to innovation,
                We uniquely put power in your hands.
              </p>
            </Animate.Fade>
          </div>

          {breakPoint.sm ? (
            <MobileCarousel className="w-full" />
          ) : (
            <DesktopCarousel className="w-full relative overflow-x-hidden" />
          )}
        </div>
      </div>
    </section>
  );
}
