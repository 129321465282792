import React from "react";
import Tag from "../Tag";
import Buynow from "./Buynow";
import Rent from "./Rent";
import * as Animate from "react-reveal";
import HeroImage from "../../../src/assets/images/energybox/reeddi-energybox-hero-banner.webp";

export default function Jumbotron({ handleShowModal, modalComponent }) {
  return (
    <>
      <header className="relative pt-8 md:pt-12 h-[80%] bg-white reeddi-capsule-abstract">
        <div className="w-11/12 m-auto pt-12 pb-28 lg:pb-32 max-w-7xl">
          <div className="w-full m-0 md:w-full lg:w-full">
            <div className="w-full flex flex-col gap-y-8 md:flex-row justify-between items-center">
              <div className="w-full lg:w-6/12 flex flex-col justify-start lg:justify-between items-start gap-y-4   md:gap-y-8">
                <Animate.Fade bottom>
                  <Tag text="Reeddi EnergyBox" />
                  <h1 className="w-full lg:w-9/12 xl:w-full font-semibold text-3xl md:text-3xl lg:text-4xl lg:leading-tight xl:text-5xl xl:leading-tight xl:pr-20">
                    Experience
                    <br />
                    Endless <br />
                    Power<span className="text-cstm-green">.</span>
                  </h1>
                </Animate.Fade>
              </div>

              <div className="w-full lg:w-5/12 relative md:mt-32 lg:mt-40">
                <Animate.Fade bottom>
                  <p className="text-cstm-grey text-base xl:text-lg leading-7 lg:leading-7 w-full lg:w-10/12">
                    The EnergyBox delivers uninterrupted power to a wide range
                    of appliances and devices, from lights and fans to computers
                    and medical equipment.
                  </p>

                  <div className="flex items-center space-x-4 lg:space-x-7 mt-6 md:mt-4 lg:mt-6">
                    <Rent textSize="base" />
                    <Buynow textSize="base" handleShowModal={handleShowModal} />
                  </div>
                </Animate.Fade>
              </div>
            </div>
          </div>
        </div>
        {modalComponent}
      </header>
      <div>
        <img src={HeroImage} alt="EnergyBox Hero Banner" />
      </div>
    </>
  );
}
