import React from "react";
import EnergyBoxPaymentImage from "../../assets/images/energybox/reeddi-energybox-2.webp";
import EnergyBoxAccessoriesImage from "../../assets/images/energybox/reeddi-energybox-3.webp";
import EnergyBoxAccessoriesOnlyImage from "../../assets/images/energybox/reeddi-energybox-4.webp";
import CheckIcon from "../../assets/images/reeddicapsule/green-check.svg";

const EnergyBox = [
  {
    bundleType: (
      <>
        Reeddi EnergyBox <br />
        System
      </>
    ),
    bundleName: "Reeddi EnergyBox System",
    image: EnergyBoxPaymentImage,
    link: "https://paystack.com/buy/reeddi-enerygybox",
    inStock: true,
    price: 449000,
    benefits: [
      {
        icon: CheckIcon,
        text: "1 Reeddi EnergyBox",
        isAvailable: true,
      },

      {
        icon: CheckIcon,
        text: "1 solar panel",
        isAvailable: false,
      },
      {
        icon: CheckIcon,
        text: "1 solar cable",
        isAvailable: false,
      },
    ],
  },
];

const EnergyBoxAccessories = [
  {
    bundleType: "Reeddi EnergyBox System with accessories",
    bundleName: "Reeddi EnergyBox System with accessories",
    image: EnergyBoxAccessoriesImage,
    link: "https://paystack.com/buy/reeddi-energybox-with-accessories",
    inStock: true,
    price: 529000,
    benefits: [
      {
        icon: CheckIcon,
        text: "1 Reeddi EnergyBox",
        isAvailable: true,
      },
      {
        icon: CheckIcon,
        text: "1 solar panel",
        isAvailable: true,
      },
      {
        icon: CheckIcon,
        text: "1 solar cable",
        isAvailable: true,
      },
    ],
  },
];

const EnergyBoxAccessoriesOnly = [
  {
    bundleType: "Reeddi EnergyBox Accessories",
    bundleName: "Reeddi EnergyBox Accessories",
    image: EnergyBoxAccessoriesOnlyImage,
    link: "",
    price: 80000,
    benefits: [
      {
        icon: CheckIcon,
        text: "1 solar panel",
        isAvailable: true,
      },
      {
        icon: CheckIcon,
        text: "1 solar cable",
        isAvailable: true,
      },
      {
        icon: CheckIcon,
        text: "2 rechargeable bulbs",
        isAvailable: true,
      },
      {
        icon: CheckIcon,
        text: "1 rechargeable standing fan",
        isAvailable: true,
      },
    ],
  },
];

export const BundleData = [
  EnergyBox,
  EnergyBoxAccessories,
  // EnergyBoxAccessoriesOnly,
];
