import React, { useState } from "react";
import Layout from "../containers/Layout";
import { Contact } from "../components";
import {
  Jumbotron,
  Diff,
  Capacity,
  Features,
  Bundles,
  Showcase,
  Stickybar,
  PowerDevice,
  BuyNowModal,
  Measures,
  Specifications,
} from "../components/EnergyBoxComponents";
// import { Cta } from "../components/AmbassadorComponents";
import Modallayout from "../containers/Modallayout";
// import BuyNowModal from "../components/ReeddiCapsuleComponents/BuyNowModal";

export default function ReeddiEnergyBox() {
  const [showBuyModal, setShowBuyModal] = useState(false);
  const handleShowModal = () => {
    setShowBuyModal(true);
  };

  return (
    <Layout
      title={"Reeddi EnergyBox"}
      stickyComponent={<Stickybar handleShowModal={handleShowModal} />}
    >
      <Jumbotron
        handleShowModal={handleShowModal}
        modalComponent={<Modallayout />}
      />
      <Diff />
      <Capacity />
      <Specifications />
      <PowerDevice />
      <Showcase />
      <Features />
      <Measures />
      <Bundles />
      <Contact />

      {/* Buy Now Modal */}
      <BuyNowModal
        showBuyModal={showBuyModal}
        handleClose={() => setShowBuyModal(false)}
      />
    </Layout>
  );
}
