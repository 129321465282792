import React from "react";
// import { useBreakpoint } from "gatsby-plugin-breakpoints";
import * as Animate from "react-reveal";
import CheckIcon from "../../assets/images/reeddicapsule/check.png";
// import CapsuleIconSM from "../../assets/images/reeddicapsule/capsule-quality.png";
// import CapsuleIconLG from "../../assets/images/reeddicapsule/girl-with-capsule.png";

const powerMap = [
  {
    id: "1",
    icon: CheckIcon,
    title: "Power Capability",
    text: "EnergyBox powers more than 80% of household devices & machineries.",
  },
  {
    id: "2",
    icon: CheckIcon,
    title: "GHG Emission",
    text: "36 KgCO2 of GHG emission offset per day from existing fuel-based substitutes for each systems.",
  },

  {
    id: "3",
    icon: CheckIcon,
    title: "Hybrid",
    text: "The EnergyBox can be powered with solar & direct grid electricity.",
  },
  {
    id: "4",
    icon: CheckIcon,
    title: "Life Span",
    text: "The system has 8+ years life-span before the system drops to <60% capacity. Highly durable, built to last.",
  },

  {
    id: "5",
    icon: CheckIcon,
    title: "Fast Charging",
    text: "In 90 minutes, it charges up from 0 to 100% with Grid Electricity & in 3-4 hours with >170W solar panels.",
  },
];

function PowerCard({ powerFeature }) {
  const { title, text } = powerFeature;
  return (
    <Animate.Fade left>
      <div className="shadow-lg rounded-xl px-4 lg:px-8 py-8 flex flex-col justify-between items-start bg-white">
        <div className="flex flex-col space-y-3">
          <h4 className="text-lg lg:text-xl font-semibold text-cstm-green">
            {title}
          </h4>

          <p className="text-base text-cstm-grey md:h-[8rem] lg:h-[4rem] xl:h-[5rem]">
            {text}
          </p>

          <div className="cstm-tempown-gradient-dark h-3 w-full mt-6" />
        </div>
      </div>
    </Animate.Fade>
  );
}

// const mobileImg = CapsuleIconSM;
// const desktopImg = CapsuleIconLG;

export default function Capacity() {
  // const breakPoint = useBreakpoint();

  return (
    <>
      <section className="bg-[white]">
        <div className="w-11/12 m-auto py-16 lg:pt-24 lg:pb-20 max-w-7xl">
          <div className="flex flex-col gap-y-8 md:flex-row justify-between lg:justify-start items-center gap-x-16">
            <div className=" w-full flex flex-col gap-y-8">
              <div className="flex flex-col items-center gap-y-4 lg:gap-y-6">
                <Animate.Fade bottom>
                  <h3 className="text-3xl text-center font-semibold capitalize md:text-4xl lg:text-5xl lg:w-7/12 lg:leading-[57px] lg:mx-auto">
                    EnergyBox go beyond just powering devices.
                  </h3>
                  <p className="text-cstm-grey text-base lg:w-6/12 lg:mx-auto text-center">
                    With its clean and sustainable energy, you’ll be
                    contributing to a greener future while also saving money on
                    costly energy bills.
                  </p>
                </Animate.Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="main-abstract-bg">
        <div className="w-11/12 mx-auto py-16 lg:py-24 max-w-7xl">
          <h2 className="text-[#ffffff] font-bold  text-left text-3xl mb-4 md:text-4xl lg:text-5xl lg:leading-tight w-full md:w-5/12 xl:pr-8 capitalize">
            Features
          </h2>
          <div className="mt-4 h-[3px] bg-white w-full md:w-3/12 lg:w-2/12 xl:pr-8" />

          <div className="mt-16 lg:mt-20">
            <Animate.Fade bottom>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-12">
                {powerMap.slice(0, 3).map((feature) => {
                  return <PowerCard key={feature.id} powerFeature={feature} />;
                })}
              </div>
              <div className="flex flex-col space-y-6 mt-6 md:flex-row md:space-y-0 md:space-x-6 lg:mt-12 lg:justify-center lg:max-w-[1000px] lg:mx-auto">
                {powerMap.slice(3).map((feature) => {
                  return <PowerCard key={feature.id} powerFeature={feature} />;
                })}
              </div>
            </Animate.Fade>
          </div>
        </div>
      </section>
    </>
  );
}
