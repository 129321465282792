import React from "react";
import Img1 from "../../../assets/images/energybox/usage_cost.webp";
import Img1B from "../../../assets/images/bigenergy/barchart-1.webp";

import Img2 from "../../../assets/images/energybox/mobility.webp";
import Img2B from "../../../assets/images/bigenergy/barchart-2.webp";

import Img3 from "../../../assets/images/energybox/ease_of_use.webp";
import Img3B from "../../../assets/images/bigenergy/barchart-3.webp";

import Img4 from "../../../assets/images/energybox/health_impact.webp";
import Img4B from "../../../assets/images/bigenergy/barchart-4.webp";

import Img5 from "../../../assets/images/energybox/environmental_impact.webp";
import Img5B from "../../../assets/images/reeddicapsule/barchart-5.webp";

import CompareIcon from "../../../assets/images/energybox/comparisons.webp";

const dataMap = [
  {
    id: "1",
    image: Img1,
    title: "Usage Cost",
    text: `In contrast to other energy sources, you do not have to worry about 
    extra installation, repair, or wear and tear costs.`,
    comparison: CompareIcon,
    barchart: Img1B,
  },

  {
    id: "2",
    image: Img2,
    title: "Mobility",
    text: `The EnergyBox system’s compact size makes it very easy to carry and move 
    around. Literally, you have power in your hands everywhere you go.`,
    comparison: CompareIcon,
    barchart: Img2B,
  },

  {
    id: "3",
    image: Img3,
    title: "Ease of use",
    text: `Reeddi EnergyBox is designed to be a Plug and Play solution making it's usage
    by anyone and everyone to be seamless.`,
    comparison: CompareIcon,
    barchart: Img3B,
  },

  {
    id: "4",
    image: Img4,
    title: "Health impact",
    text: `Reeddi EnergyBox system does not pose any health impact. The system has been tested, 
    certified and passed consumer electronics safety measures making it a safe household device.`,
    comparison: CompareIcon,
    barchart: Img4B,
  },

  {
    id: "5",
    image: Img5,
    title: "Environmental Impact",
    text: (
      <>
        An EnergyBox system offsets over 147kg co<sub>2</sub> from hazardous
        fuel based substitutes currently used in our target communities.
      </>
    ),
    comparison: CompareIcon,
    barchart: Img5B,
  },
];

export { dataMap };
