import React from "react";
import * as Animate from "react-reveal";
import Icon1 from "../../assets/images/bigenergy/building.svg";
import Icon2 from "../../assets/images/bigenergy/shop-alt.svg";
import Icon3 from "../../assets/images/bigenergy/city.svg";

import EnergyBoxImage from "../../assets/images/energybox/reeddi-energybox-2.webp";

const featuresMap = [
  {
    id: "1",
    icon: Icon1,
    title: "Large Households/Families",
    subtitle: "",
  },

  {
    id: "2",
    icon: Icon2,
    title: "Small & Medium Scale Businesses",
    subtitle: "",
  },

  {
    id: "3",
    icon: Icon3,
    title: "Local Service Businesses",
    subtitle: "",
  },

  {
    id: "4",
    icon: Icon2,
    title: "Schools & Hospitals",
    subtitle: "",
  },
];

function FeatureCard({ feature }) {
  const { icon, title, subtitle } = feature;

  return (
    <div
      className="flex justify-start items-start py-6 md:py-8 px-4 md:px-6 bg-white rounded-xl w-full"
      style={{ boxShadow: "0px 31.0344px 39.9014px rgba(224, 224, 224, 0.25)" }}
    >
      <img
        src={icon}
        className="self-center w-5 md:w-auto mr-3 md:mr-4"
        alt="icon"
      />
      <div className="flex flex-col py-2 md:py-2">
        <h4 className="font-normal text-sm-15 lg:text-base">{title}</h4>
      </div>
    </div>
  );
}

export default function Features() {
  return (
    <section className="mt-20 md:mt-32 mb-10 lg:h-[440px] lg:pt-28 lg:pb-0 lg:mb-40 xl:mb-44 relative py-16 bg-cover bg-center main-abstract-bg">
      <div className="relative w-11/12 m-auto max-w-7xl">
        <div className="w-full flex flex-col md:flex-row justify-between lg:justify-start xl:gap-x-14 items-center">
          <div className="relative w-11/12 lg:mt-[-70px] mx-auto md:mx-0 md:self-start md:max-w-xl flex flex-col justify-center md:justify-start md:items-start md:w-[45%]">
            <Animate.Fade bottom>
              <h1 className="text-center font-semibold md:text-left text-3xl leading-tight md:text-4xl lg:text-4xl lg:leading-tight text-white capitalize">
                The EnergyBox System is built for
              </h1>
              <div className="mx-auto md:mx-0 mt-8 h-[3px] w-10/12 lg:w-full bg-white" />
              <div className="w-full lg:absolute lg:top-32 flex justify-center">
                <img src={EnergyBoxImage} alt="capsule-features" />
              </div>
            </Animate.Fade>
          </div>

          <div className="relative md:absolute md:right-0 md:-mt-10 flex flex-col items-start md:w-[45%] md:ml-4 mt-10 space-y-6 lg:mt-[165px] xl:mt-[175px]">
            {featuresMap.map((feature) => {
              return <FeatureCard key={feature.id} feature={feature} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
